import Letters from './SvgIcons.svg';

const SvgIcons = ({ name, color, size }: any) => (
   <>
      <svg className="svg-name" viewBox="0 0 24 24" fill={color} width={size} height={size}>
         <use href={`${Letters}#${name}`} />
      </svg>
   </>
);

SvgIcons.defaultProps = {
   size: 35,
   color: 'none',
};

export default SvgIcons;