import {ARTWORK_ENDPOINT, BASE_URL, CATAGORY_ENDPOINT } from "../../common/constant/Constant"
import SvgIcons from "../../common/svg/SvgIcons"

const List = (props: any) => {
    const { form, editFields, removeFields, dataType } = props
    return (
        <li className="banner_item">
            <div className="banner_list_content">
                {form.imgUrl && 
                    <div className="img">
                        <img alt="logo" src={`${BASE_URL}files/${form.imgUrl}`} />
                    </div>
                }
                    <div className="heading">
                        {form.heading!="undefined" && 
                            <>{form.heading}</>
                        }
                    </div>
                <div className="description">
                    {form.description!="undefined" && 
                        <>{form.description}</>
                    }
                </div>
                {props.dataType == ARTWORK_ENDPOINT &&
                    <div className="catagory">
                        {form.catagory}
                    </div>
                }
            </div>
                <div className="action">
                    {dataType !== CATAGORY_ENDPOINT &&
                        <button className="btn edit" onClick={() => editFields(form.id)}><SvgIcons name="edit_icon" size="15"/>Edit</button>
                    }
                        <button className="btn delete error" onClick={(e) => removeFields(e, form.id)}><SvgIcons name="remove_icon" size="15"/> Remove</button>
                </div>
        </li>
    )
}

export default List