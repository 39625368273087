import React from 'react'
import {CATAGORY_ENDPOINT } from '../../common/constant/Constant'
import CommonUtils from '../common/CommonUtils'

const Catagory = () => {
    return (
        <CommonUtils dataType={CATAGORY_ENDPOINT}/>
    )
}

export default Catagory
