import { Outlet, NavLink } from "react-router-dom";
import { ROUTE_NAME } from "../../constant/constant";
import logo from '../../logo.svg'
import SvgIcons from "../svg/SvgIcons";
import './Navigation.scss'

const Navigation = ({toggle, doSomething}:any) => {
  return (
    <>
      <div className={`navigation ${toggle?'open':''}`}>
        <span className="brand"><img src={logo} alt="logo"/></span>
        <ul onClick={() => doSomething()}>
          {/* @ts-ignore */}
          <li><NavLink activeClassName='active' to={ROUTE_NAME.DASHBOARD}><SvgIcons name="dashboard_icon" size="25"/> Dashboard</NavLink></li>
          {/* @ts-ignore */}
          <li><NavLink activeClassName='active' to={ROUTE_NAME.BANNER}><SvgIcons name="dashboard_icon" size="25" /> Banners</NavLink></li>
          {/* @ts-ignore */}
          <li><NavLink activeClassName='active' to={ROUTE_NAME.ARTWORK}><SvgIcons name="dashboard_icon" size="25" /> Art Work</NavLink></li>
          {/* @ts-ignore */}
          <li><NavLink activeClassName='active' to={ROUTE_NAME.GATAGORY}><SvgIcons name="dashboard_icon" size="25" /> Catagory</NavLink></li>
        </ul>
      </div>
      <Outlet />
    </>
  )
};

export default Navigation;
