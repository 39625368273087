import React from 'react'
import { ARTWORK_ENDPOINT } from '../../common/constant/Constant'
import CommonUtils from '../common/CommonUtils'
import './ArtWork.scss'

const ArtWork = () => {
    return (
        <CommonUtils dataType={ARTWORK_ENDPOINT}/>
    )
}

export default ArtWork
