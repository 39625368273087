import React, { useState, useEffect } from "react";
import axios from 'axios';
import authHeader from '../../services/auth-header';
import { useNavigate } from 'react-router-dom'
import './CommonUtils.scss'
import Modal from "../../common/modal/Modal";
import { BASE_URL, ARTWORK_ENDPOINT, BANNERS_ENDPOINT, CATAGORY_ENDPOINT, CATAGORY_ALL } from "../../common/constant/Constant";
import SvgIcons from "../../common/svg/SvgIcons";
import List from "./List";
// import { setAllcatagoryData } from "../../funnelSlice";

export type FormData = {
    heading: string;
    description: string;
    catagory: string;
    imgUrl: string;
}

export type PropsType = {
    todos: FormData[];
    handleAddToDo: (task: string) => void;
}

export type restData = {
    heading: String, catagory: String, description: String
}
  
const CommonUtils = (props:any) => {
    const [limitBanner, setLimitBanner] = useState(5);
    const [isdisable, setIsDisabled] = useState(false);
    const [allData, setAllData] = useState<FormData[]>([])
    const [formFields, setFormFields] = useState<FormData[]>([])
    const [isEdit, setIsEdit] = useState(false);
    const [formDatatId, setFormDatatId] = useState<number>();
    const [preview, setPreview] = useState<string>()
    const [sessionExpored, setSessionExpored] = useState<string>()
    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false)
    const [showDataModal, setShowDataModal] = useState<boolean>()
    const [formModalData, setFormModalData] = useState<restData[]>([])
    const [apiEndPoint, setApiEndPoint] = useState(props.dataType===BANNERS_ENDPOINT?BANNERS_ENDPOINT:props.dataType===CATAGORY_ENDPOINT?CATAGORY_ENDPOINT:ARTWORK_ENDPOINT)
    const [descriptionPlaceHolder, setDescriptionPlaceHolder] = useState(props.dataType===ARTWORK_ENDPOINT?"Price":"Description")
    const [titlePlaceHolder, setTitlePlaceHolder] = useState("Title")
    const [sectionTitle, setSectionTitle] = useState(apiEndPoint)
    const [catagoryList, setCatagoryList] = useState([])
    const [selectCatagoryName, setSelectCatagoryName] = useState(CATAGORY_ALL)
    const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false)
    const navigate = useNavigate();

    // @ts-ignore
    useEffect(() => {
        // @ts-ignore
        fetData()
        const catListLocal = localStorage.getItem(CATAGORY_ENDPOINT)
        // @ts-ignore
        const catList = JSON.parse(catListLocal)
        // @ts-ignore
        setCatagoryList(catList)

    }, [])
    if (sessionExpored) {
        window.localStorage.clear()
        alert(sessionExpored)
        navigate('/login');
    }
    const fetData = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${BASE_URL}${apiEndPoint}`,
                headers: authHeader()
            });
            setAllData(response.data)
            setFormFields(response.data)
            if (selectCatagoryName != CATAGORY_ALL) {
                debugger
                handleFilterResult(selectCatagoryName, 'catagory', response.data)
            }
            if (response.data.length >= limitBanner) {
                setIsDisabled(true)
            } else {
                setIsDisabled(false)
            }
            if (response.request.responseURL.match(/\w+$/)[0] == CATAGORY_ENDPOINT) {
                localStorage.setItem("catagory", JSON.stringify(response.data));
            }
            if (response.data.result == "Invalid Token") {
                setSessionExpored("Login session expired please login again")
            }
            // @ts-ignore
        } catch (error) {
            console.log(error)
        }
    }
    console.log(formFields,'formFields')
    const onCancel = (value:any) => {
        setShowDataModal(value)
    }
    const editData = async (e:any) => {
        e.preventDefault();
        try {
            const response = await axios({
                method: "PUT",
                url: `${BASE_URL}${apiEndPoint}/${formDatatId}`,
                data: formDataPayLoad(),
                headers: authHeader()
            });
            setShowDataModal(false)
            fetData()
            // @ts-ignore
            // fetData("GET")
        } catch(error) {
            console.log(error)
        }
    }

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name = event.target.name;
        let value: any = '';
        if (!event.target.files) {
            value = event.target.value;
        } else {
            value = event.target.files[0];
            const objectUrl:any = URL.createObjectURL(value)
            setPreview(objectUrl)
        }
        // @ts-ignore
        setFormModalData(values => ({...values, [name]: value}))
    }
    
    
    const handleAddToDo = (value:any) => {
        setFormModalData([])
        setPreview('')
        setIsEdit(false)
        setShowDataModal(true)
        setShowErrorMsg(false)
        if (selectCatagoryName!=CATAGORY_ALL) {
            setFormModalData(values => ({...values, 'catagory': selectCatagoryName}))
        }
    }

    const removeFields = async(e:any, id:number) => {
        e.preventDefault();
        setDeleteConfirmation(true)
        setFormDatatId(id)
    }
    const confirmDelete = async() => {
        try {
            const response = await axios({
                method: "DELETE",
                url: `${BASE_URL}${apiEndPoint}/${formDatatId}`,
                data: formDataPayLoad(),
                headers: authHeader()
            });
            fetData()
            setDeleteConfirmation(false)
            // @ts-ignore
            // fetData("GET")
        } catch(error) {
            console.log(error)
        }
    }
    const editFields = (dataID: number) => {
        let data = [...formFields];
        // @ts-ignore
        const result = data.filter((item) => item.id === dataID);
        // @ts-ignore
        setFormModalData(result[0])
        setIsEdit(true)
        setFormDatatId(dataID)
        setShowErrorMsg(false)
        setShowDataModal(true)
        setSelectCatagoryName(result[0].catagory)
        // @ts-ignore
        setPreview(`${BASE_URL}files/${result[0].imgUrl}`)
    }

    const formDataPayLoad = () => {
        const formData = new FormData();
        // @ts-ignore
        const imgUrl = formModalData?.imgUrl
        // @ts-ignore
        const heading = formModalData?.heading
        // @ts-ignore
        const catagory = formModalData?.catagory
        // @ts-ignore
        // @ts-ignore
        const description = formModalData?.description
        formData.append("file",imgUrl);
        formData.append("heading",heading);
        formData.append("description", description);
        formData.append("catagory", catagory);
        return formData
    }
    
    const handleSelectChange = (event: any) => {
        let value = event.target.value
        let name = event.target.name
        handleFilterResult(value, name, '')
        setSelectCatagoryName(value)
    }

    const handleFilterResult = (value: any, name: any, resData:any) => {
        setFormModalData(values => ({ ...values, [name]: value }))
        if (value===CATAGORY_ALL) {
            setFormFields(allData)
        } else {
            if (resData) {
                const data = resData.filter((item:any) => item.catagory == value)
                setFormFields(data)
            } else {
                const data = allData.filter((item) => item.catagory == value)
                setFormFields(data)
            }
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await axios({
              method: "post",
              url: `${BASE_URL}${apiEndPoint}`,
                data: formDataPayLoad(),
                headers: authHeader()
            });
            fetData()
            setShowDataModal(false)
            setShowErrorMsg(false)
          } catch(error) {
            console.log(error)
            setShowErrorMsg(true)
          }
    
        // return data;
         
        // http://localhost:8080/${apiEndPoint}
    }
    return (
        <section className={`banner_wrap ${sectionTitle}`}>
            <div className="head">
                <h3 className="title">{sectionTitle}</h3>
                <div className="action">
                    {/* @ts-ignore */}
                    {props.dataType == ARTWORK_ENDPOINT &&
                        <select defaultValue={selectCatagoryName} name="catagory" onChange={event => handleSelectChange(event)}>
                            <option selected>{CATAGORY_ALL}</option>
                            {catagoryList && catagoryList.length>0 && catagoryList.map((option) =>
                                // @ts-ignore
                                <option>{option.heading}</option>
                            )}
                        </select>
                    }
                    {/* @ts-ignore */}
                    <button className="btn" disabled={sectionTitle===BANNERS_ENDPOINT ? isdisable:false} onClick={()=>handleAddToDo(sectionTitle)}>Add {sectionTitle}</button>
                </div>
            </div>
            <div className="content">
                
                <ul>
                    {formFields && formFields.length > 0 && formFields.slice(0, 200).map((form, index) => (
                        <List dataType={props.dataType} form={form} removeFields={removeFields} editFields={editFields} key={index} />
                    ))}
                </ul>
            </div>
            <Modal show={showDataModal} customClasss="banner">
                <div className={`banner_content ${preview?"bg_img":''}`} style={{ 
                    backgroundImage: `url("${preview}")`
                }}>
                    {props.dataType !== CATAGORY_ENDPOINT &&
                        <div className="img_file">
                            <input
                                name='imgUrl'
                                type="file"
                                onChange={event => handleFormChange(event)}
                                />
                        {/* @ts-ignore */}
                        {!preview && showErrorMsg &&
                            <div className="error_msg">Please upload image</div>
                        }
                        </div>
                    }
                    {props.dataType == ARTWORK_ENDPOINT &&
                        <div className="catagory">
                        {/* @ts-ignore */}
                                <select defaultValue={selectCatagoryName} name="catagory" onChange={event => handleFormChange(event)}>
                                    <option>Select Catagory</option>    
                                    {catagoryList && catagoryList.length>0 && catagoryList.map((option) =>
                                        // @ts-ignore
                                        <option>{option.heading}</option>
                                        )}
                            </select>
                        </div>
                    }
                    <div className="heading">
                        <input
                            name='heading'
                            type="text"
                            placeholder={titlePlaceHolder}
                            // @ts-ignore
                            style={{width: `${formModalData?.heading?.length}ch`}}
                            onChange={event => handleFormChange(event)}
                            // @ts-ignore
                            value={formModalData?.heading}
                        />
                    </div>
                    <div className="description">
                        <input
                            name='description'
                            type={props.dataType == ARTWORK_ENDPOINT?"number":"text"}
                            placeholder={descriptionPlaceHolder}
                            // @ts-ignore
                            style={{width: `${formModalData?.description?.length}ch`}}
                            onChange={event => handleFormChange(event)}
                            // @ts-ignore
                            value={formModalData?.description}
                            />
                    </div>
                </div>
                <div className="action">
                    {props.dataType == ARTWORK_ENDPOINT ? 
                        <>
                            {/* @ts-ignore */}
                            {!isEdit ? (<button className="btn success" disabled={!formModalData?.heading?.length || !formModalData?.catagory || formModalData?.catagory=="Select Catagory"} type="submit" onClick={(e) => handleSubmit(e)}>Submit</button>) : <button className="btn success" type="submit" disabled={!formModalData?.heading?.length} onClick={(e) => editData(e)}>Update</button>}
                        </> : <>
                            {/* @ts-ignore */}
                            {!isEdit ? (<button className="btn success" disabled={!formModalData?.heading?.length} type="submit" onClick={(e) => handleSubmit(e)}>Submit</button>) : <button className="btn success" type="submit" disabled={!formModalData?.heading?.length} onClick={(e) => editData(e)}>Update</button>}
                        </>
                    }
                    
                    <button className="btn error" type="submit" onClick={()=>onCancel(false)}>Cancel</button>
                </div>
            </Modal>
            <Modal show={deleteConfirmation} yesConfirm={() => confirmDelete()} primaryBtn="Yes" secondaryBtn="No" noConfirm={() => setDeleteConfirmation(false)}>
            Are you sure want to delete
            </Modal>
        </section>
    )
}

export default CommonUtils