import React from 'react'
import './Modal.scss'

const Modal = (props: any) => {
    return (
        <>
            {props.show == true &&
                <div className={`modal ${props.show ?? 'show'} ${props.customClasss??props.customClasss}`}>
                    <div className={`modal_container ${props.src?"bg_img":''}`}>
                        <div className="modal_head"></div>
                        <div className="modal_body">
                            {props.children}
                        </div>
                        {props.primaryBtn && props.secondaryBtn &&
                            <div className="modal_foot">
                                {props.primaryBtn &&
                                    <button className="btn" onClick={props.yesConfirm}>{props.primaryBtn}</button>
                                }
                                {props.secondaryBtn &&
                                    <button className="btn error" onClick={props.noConfirm}>{props.secondaryBtn}</button>
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Modal
