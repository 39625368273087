import './Header.scss'
import {Link } from "react-router-dom";
import SvgIcons from '../svg/SvgIcons';
import { useNavigate } from 'react-router-dom'


const Header = ({ clickHandlerToggle, currentUser }: any) => {
    const navigate = useNavigate();
    const logoutHandle = () => {
        window.localStorage.clear()
        navigate('/login');
    }
    console.log(currentUser,'currentUser !!')
    const loginHandle = () => {
        navigate('/login');
    }
    return (
        <header>
            <div className="left">
                <span className="expand" onClick={clickHandlerToggle}><SvgIcons name="nav_icon"/></span> Dashboard</div>
            <div className="right">
                <div className="link">
                    {/* <Link to={"/login"} className="nav-link">
                    Login
                    </Link> */}
{/* 
                    <Link to={"/register"} className="nav-link">
                    Sign Up
                    </Link> */}
                </div>
                <div className="profile">
                    <SvgIcons name="profile_icon" />
                    <div className="dropdown">
                        <ul>
                            {/* <li>Profile <SvgIcons name="profile_icon" size={20}/></li> */}
                            {currentUser?
                                <li onClick={()=>logoutHandle()}>Logout <SvgIcons name="logout_icon" size={20}/></li>
                                :
                                <li onClick={()=>loginHandle()}>Login <SvgIcons name="logout_icon" size={20}/></li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
