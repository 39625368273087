import React,{ useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

type Props = {};

type State = {
  content: string;
}

const BoardUser = () => {
  const [state, setState] = useState()

  useEffect(() => {
    UserService.getUserBoard().then(
      response => {
        setState(response.data);
      },
      error => {
        setState(error.response);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, [])

  console.log(state,'state')
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{state}</h3>
        </header>
      </div>
    );
}

export default BoardUser