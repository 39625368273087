import React,{ useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.scss";

import AuthService from "./services/auth.service";
import IUser from './types/user.type';

import Login from "./components/login.component";
import Register from "./components/register.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";

import EventBus from "./common/EventBus";
import Navigation from "./common/navigation/Navigation";
import Dashbaord from "./components/Dashbaord";
import Header from "./common/header/Header";
import { ROUTE_NAME } from "./constant/constant";
import ArtWork from "./components/art-work/ArtWork";
import Catagory from "./components/catagory/Catagory";
import Banners from "./components/banner/Banner";


export interface RouteComponent {
  path?: string;
  element?: React.ComponentType<any>;
  children?: RouteComponent[];
}

const App: React.FC<RouteComponent> = () => {
// class App extends Component<Props, State> {
  const [toggle, setToggle] = useState(false);
  const [state, setState] = useState({
    showModeratorBoard: false,
    showAdminBoard: false,
    currentUser: undefined,
  })

  const clickHandlerToggle = () => {
    setToggle(!toggle);
  }

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setState({
        currentUser: user,
        showModeratorBoard: user?.roles?.includes("ROLE_MODERATOR"),
        showAdminBoard: user?.roles?.includes("ROLE_ADMIN"),
      });
    }

    EventBus.on("logout", logOut);
    return () => {
      EventBus.remove("logout", logOut);
    }
  }, [])

  const logOut = () => {
    AuthService.logout();
    setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }
  const doSomething = () => {
    setToggle(false);
  }
    const { currentUser, showModeratorBoard, showAdminBoard } = state;
    return (
      <>
          <Navigation toggle={toggle} doSomething={doSomething}/>
          <div className="container">
            <Header clickHandlerToggle={clickHandlerToggle} currentUser={currentUser}/>
            <div className="content-wrap">
              <Routes>
                <Route path={ROUTE_NAME.DASHBOARD} element={<Dashbaord />} />
                <Route path={ROUTE_NAME.BANNER} element={<Banners />} />
                <Route path={ROUTE_NAME.ARTWORK} element={<ArtWork/>} />
                <Route path={ROUTE_NAME.GATAGORY} element={<Catagory />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/user" element={<BoardUser />} />
                <Route path="/mod" element={<BoardModerator />} />
                <Route path="/admin" element={<BoardAdmin />} />
              </Routes>
            </div>
          </div>
          { /*<AuthVerify logOut={this.logOut}/> */}
      </>
    );
}

export default App;