import React from 'react'
import { ARTWORK_ENDPOINT, BANNERS_ENDPOINT, CATAGORY_ENDPOINT } from '../common/constant/Constant'
import CommonUtils from './common/CommonUtils'

const Dashbaord = () => {
    return (
        <div className="dashboard_main">
            <CommonUtils dataType={BANNERS_ENDPOINT}/>
            <CommonUtils dataType={ARTWORK_ENDPOINT}/>
            <CommonUtils dataType={CATAGORY_ENDPOINT}/>
        </div>
    )
}

export default Dashbaord
