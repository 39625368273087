import React from 'react'
import { ARTWORK_ENDPOINT, BANNERS_ENDPOINT } from '../../common/constant/Constant'
import CommonUtils from '../common/CommonUtils'

const Banners = () => {
    return (
        <CommonUtils dataType={BANNERS_ENDPOINT}/>
    )
}

export default Banners
